
import { defineComponent } from 'vue';
import NProgress from 'nprogress';

export default defineComponent({
  name: 'Sidebar',
  computed: {
    currentRoute() {
      return this.$router.currentRoute.value;
    },
    routeHash() {
      return this.currentRoute.hash ? this.currentRoute.hash : null;
    },
    isShowingSideBar() {
      return this.$store.state.isShowingSideBar;
    },
  },
  methods: {
    onLinkClick() {
      NProgress.done();
      if (this.isShowingSideBar) this.$store.dispatch('toggleSideBar');
    },
  },
});
