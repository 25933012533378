
/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
import { defineComponent } from 'vue';
import NProgress from 'nprogress';
import Sidebar from '@/layout/Sidebar.vue';

export default defineComponent({
  name: 'Docs',
  components: {
    Sidebar,
  },
  mounted() {
    NProgress.done();
  },
  data() {
    return {
      vueTag: "<vue3-autocounter ref='counter' :startAmount='0' :endAmount='2021' :duration='3' prefix='$' suffix='USD' separator=',' decimalSeparator='.' :decimals='2' :autoinit='true' @finished='alert(`Counting finished!`)'/>",
    };
  },
});
